import React from 'react';

export default function Form() {
  return (
    <>
      <h2>Contact Me</h2>
      <p>
        Fields marked with an <Asterisk /> are required
      </p>
      <form name="Contact" method="POST" data-netlify="true">
        <label className="mt-6">
          <div>
            <b>Name</b> <Asterisk />
          </div>
          <input
            type="text"
            className="bg-gray-100 border border-gray-400 h-10 px-2 mt-2 w-full"
            name="Name"
            required
          />
        </label>

        <label>
          <div className="mt-6">
            <b>Email</b> <Asterisk />
          </div>
          <input
            type="email"
            className="bg-gray-100 border border-gray-400 h-10 px-2 mt-2 w-full"
            name="Email"
            required
          />
        </label>
        <label>
          <div className="mt-6">
            <b>Message</b> <Asterisk />
          </div>
          <textarea
            className="bg-gray-100 border border-gray-400 px-2 mt-2 w-full"
            rows="10"
            name="Message"
            required
          />
        </label>
        <label className="block mt-6 flex items-center">
          <input type="checkbox" name="Newsletter" className="w-5 h-5 mr-2" />{' '}
          <b className="pointer-events-none">Join Newsletter</b>
        </label>

        <button className="bg-gray-800 text-white py-2 px-4 mt-6" type="submit">
          Submit
        </button>
      </form>
    </>
  );
}

const Asterisk = () => <span className="text-red-600 font-semibold">*</span>;
