/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
import Form from '../components/Form';
import Layout from '../components/Layout';
import SEO from '../components/seo/seo';
import { AlertWork } from '../components/ui/alert/alert';

export default function About({ data }) {
  const image = getImage(data.hero);
  const imgStack = ['linear-gradient(rgba(0,0,0,0.4),rgba(0,0,0,0.4))', image];
  return (
    <Layout>
      <SEO />
      <BgImage
        image={imgStack}
        css={css`
          &::after,
          &::before {
            background-position: 50% 10%;
            background-size: cover;
          }
        `}
        style={{ backgroundPosition: '', backgroundSize: '' }}
      >
        <div className="">
          <div className="container py-12  md:pb-24 md:pt-24 ">
            <h1 className="text-gray-100 font-bold text-3xl md:text-5xl mt-0">About</h1>
          </div>
        </div>
      </BgImage>
      <div className="container py-6">
        <AlertWork />
        <h2>The Blog</h2>
        <p>
          As a lifelong learner, I want to help share as much as I can to help you lead a more
          fulfilling and productive life. I will detail my journey on learning how to discover and
          create opportunities. As well as systems, habits, and productivity methods I use to live
          as much as I can within a day. I'll share Life lessons and stories from challenging and
          other interesting experiences to shed light on the characteristics of grit,
          resourcefulness, persistence and adaptability. In addition, I will share tools I come
          across that help me expand my capabilities and other principles and mindsets to grow
          personally and professionally.
        </p>
        <h2>About me</h2>
        <div>
          <div className="float-left mr-4">
            <GatsbyImage image={data.thumb.childImageSharp.gatsbyImageData} alt="juvoni" />
          </div>
          <p>
            Juvoni Beckford is a Senior Software Engineer exploring the meta-skill of “Learning How
            to Learn”, optimizing his personal life as much as he optimizes and automates external
            systems. Most recently he was an engineer at Lifion, an R&D company by ADP, building a
            next-generation HR platform as a service envisioned to be the “Operation System” of HR.
            Prior to that, he worked at American Express on the core team that rebuilt and launched
            the new AmericanExpress.com front-end experience used by millions of AMEX cardmembers.
          </p>
        </div>
        <p>
          I am also a voracious reader and deep listener. I hope my blog would serve as a place for
          a lively discussion towards helping each other becoming the strongest version of
          ourselves.
        </p>
        <div className="mb-12">
          <Form />
        </div>
      </div>
    </Layout>
  );
}
export const query = graphql`
  {
    thumb: file(relativePath: { eq: "juvoni-about-thumb.png" }) {
      childImageSharp {
        gatsbyImageData(width: 150, height: 150, layout: FIXED, formats: [AUTO, WEBP])
      }
    }
    hero: file(relativePath: { eq: "juvoni-about-hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
      }
    }
  }
`;
